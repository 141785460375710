.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bg-col1, .bg-col2, .bg-col3, .bg-col4 {
  background-image: none;
}


.bg-col1:hover, .bg-col1:focus,.bg-col1-selected {
  background-image: url('../public/stark_anime.png');
  background-position: bottom right;
  background-repeat: no-repeat;

}


.bg-col2:hover,.bg-col2-selected {
  background-image: url('../public/game_brain.png');
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bg-col3:hover,.bg-col3-selected {
  background-image: url('../public/col3_girl.png');
  background-position: bottom right;
  background-repeat: no-repeat;

}

.bg-col4:hover,.bg-col4-selected {
  background-image: url('../public/col4_girl.png');
  background-position: bottom right;
  background-repeat: no-repeat;
 
}

.about-sidemenu {
  background-image: url('../public/stark_anime.png');
  background-position: bottom right;
  background-repeat: no-repeat;

}